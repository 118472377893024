import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { get } from 'lodash';

import RegistryButton from '../RegistryButton';
import { FormattingUtils } from '../../../utils';
import { ICase } from '../../../interfaces';
import { SessionStoreClass } from '../../../stores';

const { toKey } = FormattingUtils;

interface IProps {
  _case: ICase;
}

interface IInjected extends IProps {
  SessionStore: SessionStoreClass;
}

@inject('SessionStore')
@autoBindMethods
@observer
class TabLawFirmPortal extends Component<IProps> {
  private get injected () {
    return this.props as IInjected;
  }

  private get hasRegistryCase () {
    return !!this.props._case.registry_case;
  }

  private get registryCaseUrl () {
    const { _case } = this.props
      , { SessionStore } = this.injected
      , impersonatedUserQuery = !!SessionStore.impersonatedUserId
        ? toKey({ impersonated_user_id: SessionStore.impersonatedUserId }, _case.registry_url)
        : ''
    ;
    return `${_case.registry_url}${impersonatedUserQuery}`;
  }

  private get showShareInProgressMessage () {
    return this.props._case.registry_sharing_eligibility === 'shared_pending_migration';
  }

  private get showEligibleCaseMessage () {
    return this.props._case.registry_sharing_eligibility === 'eligible_private';
  }

  public async componentDidMount () {
    await this.injected.SessionStore.fetchRegistryAccounts();
  }

  private renderIneligibilityReasons () {
    const reasons = this.props._case.registry_sharing_eligibility_summary.reasons
      , showMessageConditions = [
        [
          'case_has_open_liens_or_approved_applications',
          'The case must have open liens or approved applications.',
        ],
        [
          'case_has_open_liens',
          'The case must have open liens.',
        ],
        [
          'plaintiff_has_birthdate_or_case_has_date_of_loss',
          'A date of loss or the plaintiff\'s date of birth must be set.',
        ],
        [
          'plaintiff_has_first_name',
          'The plaintiff\'s first name must be set.',
        ],
        [
          'plaintiff_has_last_name',
          'The plaintiff\'s last name must be set.',
        ],
        [
          'funder_has_registry_migrations_enabled',
          'Your account must be configured to share cases on the law firm portal. Please contact Mighty.',
        ],
        [
          'lawfirm_is_verifed_on_registry',
          'The law firm must be verified by Mighty to use the portal. Please contact Mighty.',
        ],
      ]
    ;

    return (
      <ul>
        {showMessageConditions.filter(msg => !get(reasons, msg[0], true)).map((msg, i) => <li key={i}>{msg[1]}</li>)}
      </ul>
    );
  }

  public renderContent () {
    const { _case } = this.props
      , { SessionStore: { hasRegistryAccount } } = this.injected
      ;

    if (hasRegistryAccount === null) {
      return null;
    }

    if (!hasRegistryAccount) {
      return (
        <div className='info'>
          You do not have a law firm portal account.
          <br />
          Please contact your administrator for more information.
        </div>
      );
    }

    if (this.hasRegistryCase && this.registryCaseUrl) {
      return <iframe className='fullscreen' frameBorder='0' src={this.registryCaseUrl} />;
    }

    if (this.showShareInProgressMessage) {
      return (
        <div className='info centered'>
          This case has been shared and will be viewable on the law firm portal soon.
        </div>
      );
    }

    if (this.showEligibleCaseMessage) {
      return (
        <div className='info centered'>
          <div>
            This case is not currently shared on the law firm portal.
          </div>
          <RegistryButton _case={_case}/>
        </div>
      );
    }

    return (
      <div className='info'>
        This case is not eligible to share on the law firm portal because:
        {this.renderIneligibilityReasons()}
      </div>
    );
  }

  public render () {
    return (
      <div className='case-detail-tab tab-law-firm-portal'>
        {this.hasRegistryCase && <RegistryButton _case={this.props._case} />}
        <div className='content'>
          {this.renderContent()}
        </div>
      </div>
    );
  }
}

export default TabLawFirmPortal;
