/* tslint:disable object-literal-sort-keys */
import React from 'react';
import cx from 'classnames';
import { get } from 'lodash';

import { Button } from 'react-bootstrap';

import { FormattingUtils, TableRenderingUtils } from '../../../../utils';
import { Icon } from '../../../common';
import AppConstants from '../../../../constants/AppConstants';
import { sumMoney } from '../../../../utils/util';
import { IReactTableColumn, IReactTableItem, IReactTableRow } from '../../../../interfaces';

const {
  FUNDING_TYPES,
  TOOLTIP_ID_TABLE,
} = AppConstants;

const { formatMoney, getOrDefault } = FormattingUtils;
const {
  renderDate,
  renderHeaderWithTooltip,
  renderMoney,
  renderPercentage,
  renderUnformatted,
} = TableRenderingUtils;

const WIDTH_DATE = {
    minWidth: 120,
    maxWidth: 200,
  }
  , WIDTH_AMOUNT = {
    minWidth: 140,
    maxWidth: 200,
  };

function renderTotalLienCount (row: IReactTableRow) {
  return <div>Total ({row.data.length})</div>;
}

function renderImportedLabel (item: IReactTableItem) {
  if (!item.value) { return; }

  return (
    <div data-tip='<span>Imported</span>' data-position='bottom' data-for={TOOLTIP_ID_TABLE}>
      <Icon type='file-text' />
    </div>
  );
}

function renderSummedAmount (row: IReactTableRow) {
  const numbers = row.data.map((data: any) => data[row.column.id]);
  return <div>{formatMoney(sumMoney(numbers))}</div>;
}

function renderViewFunding (item: IReactTableItem) {
  const classNames = cx('icon-right', item.original.type.split('.')[1], {open: item.original.is_open});
  return (
    <div>
      <Button bsStyle='link' className={classNames}>
        View <Icon type='angle-double-right' />
      </Button>
    </div>
  );
}

function renderGenericLienSubtype (value: IReactTableItem) {
  return <div>{getOrDefault(get(value.row, 'subtype.label'))}</div>;
}

const HeaderLienAmount = renderHeaderWithTooltip(
    'lien-amount',
    'Lien Amount',
    'Lien Amount is the Amount to Plaintiff + Buyout (if applicable)',
  )
  , HeaderTotalPrincipal = renderHeaderWithTooltip(
    'total-principal',
    'Total Principal',
    'Total Principal is the lien amount + any fees on which interest accrues',
  )
  , HeaderCurrentLienBalance = renderHeaderWithTooltip(
    'current-lien-balance',
    'Current Lien Balance',
    'Current Lien Balance is the Current Contract Value minus Returned Amount',
  )
  , HeaderAmountPaidToMighty = renderHeaderWithTooltip(
    'amount_paid_to_coop',
    'Amt Paid To Mighty',
    'The amount paid to Mighty is the sum of past payments made to Mighty with respect to Mighty\'s investment in this lien.'
  )
  , HeaderAmountOwedToMighty = renderHeaderWithTooltip(
    'amount_owed_to_coop',
    'Amt Owed To Mighty',
    'The amount owed to Mighty is based on Mighty\'s investment in the lien, the amount returned, and any past payments made to Mighty for this lien.'
  )
  , VANITY_ID_COLUMN: IReactTableColumn = {
    Header: 'Lien Id',
    columns: [{
      accessor: 'vanity_id',
      Cell: renderUnformatted,
      minWidth: 150,
      maxWidth: 200,
      sortable: true,
    }],
  }
  , AGREEMENT_DATE_COLUMN: IReactTableColumn = {
    Header: 'Agreement',
    columns: [{
      Header: renderTotalLienCount,
      accessor: 'agreement_date',
      Cell: renderDate,
      ...WIDTH_DATE,
    }],
  }
  , INTEREST_START_DATE_COLUMN: IReactTableColumn = {
    Header: 'Interest Starts',
    columns: [{
      accessor: 'interest_start_date',
      Cell: renderDate,
      ...WIDTH_DATE,
    }],
  }
  , FUNDED_AMOUNT_COLUMN: IReactTableColumn = {
    Header: HeaderLienAmount,
    columns: [{
      Header: renderSummedAmount,
      accessor: 'invested_amount',
      Cell: renderMoney,
      ...WIDTH_AMOUNT,
    }],
  }
  , PRINCIPAL_AMOUNT_COLUMN: IReactTableColumn = {
    Header: HeaderTotalPrincipal,
    columns: [{
      Header: renderSummedAmount,
      accessor: 'principal_amount',
      Cell: renderMoney,
      ...WIDTH_AMOUNT,
    }],
  }
  , CURRENT_LIEN_BALANCE_COLUMN: IReactTableColumn = {
    Header: HeaderCurrentLienBalance,
    columns: [{
      Header: renderSummedAmount,
      accessor: 'current_lien_balance',
      Cell: renderMoney,
      minWidth: 180,
    }],
  }
  , EXPERIMENTAL_LIEN_BALANCE_COLUMN: IReactTableColumn = {
    Header: HeaderCurrentLienBalance,
    columns: [{
      Header: renderSummedAmount,
      accessor: 'experimental_lien_balance',
      Cell: renderMoney,
      minWidth: 180,
    }],
  }
  , REDUCED_LIEN_BALANCE_COLUMN: IReactTableColumn = {
    Header: 'Reduced Lien Balance',
    columns: [{
      Header: renderSummedAmount,
      accessor: 'reduced_lien_balance',
      Cell: renderMoney,
      minWidth: 180,
    }],
  }
  , IMPORT_LABEL_COLUMN: IReactTableColumn = {
    columns: [{
      accessor: 'is_imported',
      className: 'imported-column',
      Cell: renderImportedLabel,
      width: 50,
      sortable: false,
    }],
  }
  , VIEW_FUNDING_COLUMN: IReactTableColumn = {
    columns: [{
      className: 'view-funding-column',
      Cell: renderViewFunding,
      sortable: false,
      width: 100,
    }],
  }
  , DATE_CLOSED_COLUMN: IReactTableColumn = {
    Header: 'Date Closed',
    columns: [{
      accessor: 'date_closed',
      Cell: renderDate,
      ...WIDTH_DATE,
    }],
  }
  , RETURNED_AMOUNT_COLUMN: IReactTableColumn = {
    Header: 'Returned Amt',
    columns: [{
      Header: renderSummedAmount,
      accessor: 'returnAmountSum',
      Cell: renderMoney,
      ...WIDTH_AMOUNT,
    }],
  }
  , FULL_PAYOFF_AMOUNT = {
    Header: 'Expected Return Amt',
    columns: [{
      Header: renderSummedAmount,
      accessor: 'returnExpectedSum',
      Cell: renderMoney,
      minWidth: 180,
      maxWidth: 240,
    }],
  }
  , PURCHASE_DATE_COLUMN: IReactTableColumn = {
    Header: 'Purchase Date',
    columns: [{
      accessor: 'purchase_date',
      Cell: renderDate,
      ...WIDTH_AMOUNT,
    }],
  }
  , LABEL_COLUMN: IReactTableColumn = {
    Header: 'Label',
    columns: [{
      Header: renderTotalLienCount,
      accessor: 'label',
      Cell: renderUnformatted,
      minWidth: 140,
    }],
  }
  , INITIAL_SERVICE_DATE_COLUMN: IReactTableColumn = {
    Header: 'Initial Service Date',
    columns: [{
      accessor: 'initial_service_date',
      Cell: renderDate,
      ...WIDTH_DATE,
    }],
  }
  , MEDICAL_FACILITY_COLUMN: IReactTableColumn = {
    Header: 'Medical Facility',
    columns: [{
      accessor: 'medical_facility.name',
      Cell: renderUnformatted,
      minWidth: 140,
    }],
  }
  , PURCHASE_AMOUNT_COLUMN: IReactTableColumn = {
    Header: 'Purchase Amt',
    columns: [{
      Header: renderSummedAmount,
      accessor: 'purchase_amount',
      Cell: renderMoney,
      ...WIDTH_AMOUNT,
    }],
  }
  , BILLING_AMOUNT_COLUMN: IReactTableColumn = {
    Header: 'Billing Amt',
    columns: [{
      Header: renderSummedAmount,
      accessor: 'billing_amount',
      Cell: renderMoney,
      ...WIDTH_AMOUNT,
    }],
  }
  , AMOUNT_COLUMN: IReactTableColumn = {
    Header: 'Amount',
    columns: [{
      Header: renderSummedAmount,
      accessor: 'amount',
      Cell: renderMoney,
      ...WIDTH_AMOUNT,
    }],
  }
  , OWNERSHIP_PERCENTAGE_COLUMN: IReactTableColumn = {
    Header: 'Ownership %',
    columns: [{
      accessor: 'ownership_percentage',
      Cell: renderPercentage,
      ...WIDTH_AMOUNT,
    }],
  }
  , ACQUIRED_DATE_COLUMN: IReactTableColumn = {
    Header: 'Acquired Date',
    columns: [{
      accessor: 'acquired_date',
      Cell: renderDate,
      ...WIDTH_DATE,
    }],
  }
  , GENERIC_LIEN_SUBTYPE_COLUMN: IReactTableColumn = {
    Header: 'Type',
    columns: [{
      accessor: 'subtype',
      Cell: renderGenericLienSubtype,
      minWidth: 170,
    }],
  }
  , AMOUNT_PAID_TO_MIGHTY: IReactTableColumn = {
    Header: HeaderAmountPaidToMighty,
    columns: [{
      Header: renderSummedAmount,
      accessor: 'amount_paid_to_coop',
      Cell: renderMoney,
      minWidth: 180,
      maxWidth: 280,
    }],
  }
  , AMOUNT_OWED_TO_COOP_COLUMN: IReactTableColumn = {
    Header: HeaderAmountOwedToMighty,
    columns: [{
      Header: renderSummedAmount,
      accessor: 'amount_owed_to_coop',
      Cell: renderMoney,
      minWidth: 180,
      maxWidth: 280,
    }],
  }
  ;

export const IS_CLOSED: any = Number(false)
  , IS_OPEN: any = Number(true);

const FUNDING_COLUMNS: {
  [fundingType: string]: {
    [isOpen: number]: IReactTableColumn[],
  },
} = {
  [FUNDING_TYPES.PLAINTIFF]: {
    [IS_OPEN]: [
      VIEW_FUNDING_COLUMN,
      AGREEMENT_DATE_COLUMN,
      INTEREST_START_DATE_COLUMN,
      FUNDED_AMOUNT_COLUMN,
      PRINCIPAL_AMOUNT_COLUMN,
      RETURNED_AMOUNT_COLUMN,
      CURRENT_LIEN_BALANCE_COLUMN,
      EXPERIMENTAL_LIEN_BALANCE_COLUMN,
      REDUCED_LIEN_BALANCE_COLUMN,
      IMPORT_LABEL_COLUMN,
      AMOUNT_PAID_TO_MIGHTY,
      AMOUNT_OWED_TO_COOP_COLUMN,
    ],
    [IS_CLOSED]: [
      VIEW_FUNDING_COLUMN,
      AGREEMENT_DATE_COLUMN,
      PRINCIPAL_AMOUNT_COLUMN,
      DATE_CLOSED_COLUMN,
      FULL_PAYOFF_AMOUNT,
      RETURNED_AMOUNT_COLUMN,
      IMPORT_LABEL_COLUMN,
      AMOUNT_PAID_TO_MIGHTY,
      AMOUNT_OWED_TO_COOP_COLUMN,
    ],
  },
  [FUNDING_TYPES.MEDICAL]: {
    [IS_OPEN]: [
      VIEW_FUNDING_COLUMN,
      LABEL_COLUMN,
      MEDICAL_FACILITY_COLUMN,
      INITIAL_SERVICE_DATE_COLUMN,
      PURCHASE_DATE_COLUMN,
      PURCHASE_AMOUNT_COLUMN,
      BILLING_AMOUNT_COLUMN,
      RETURNED_AMOUNT_COLUMN,
      CURRENT_LIEN_BALANCE_COLUMN,
      REDUCED_LIEN_BALANCE_COLUMN,
      IMPORT_LABEL_COLUMN,
      AMOUNT_PAID_TO_MIGHTY,
      AMOUNT_OWED_TO_COOP_COLUMN,
    ],
    [IS_CLOSED]: [
      VIEW_FUNDING_COLUMN,
      LABEL_COLUMN,
      PURCHASE_DATE_COLUMN,
      PURCHASE_AMOUNT_COLUMN,
      DATE_CLOSED_COLUMN,
      FULL_PAYOFF_AMOUNT,
      RETURNED_AMOUNT_COLUMN,
      IMPORT_LABEL_COLUMN,
      AMOUNT_PAID_TO_MIGHTY,
      AMOUNT_OWED_TO_COOP_COLUMN,
    ],
  },
  [FUNDING_TYPES.GENERIC]: {
    [IS_OPEN]: [
      VIEW_FUNDING_COLUMN,
      AMOUNT_COLUMN,
      OWNERSHIP_PERCENTAGE_COLUMN,
      ACQUIRED_DATE_COLUMN,
      GENERIC_LIEN_SUBTYPE_COLUMN,
      RETURNED_AMOUNT_COLUMN,
      REDUCED_LIEN_BALANCE_COLUMN,
      AMOUNT_PAID_TO_MIGHTY,
      AMOUNT_OWED_TO_COOP_COLUMN,
    ],
    [IS_CLOSED]: [
      VIEW_FUNDING_COLUMN,
      AMOUNT_COLUMN,
      OWNERSHIP_PERCENTAGE_COLUMN,
      ACQUIRED_DATE_COLUMN,
      GENERIC_LIEN_SUBTYPE_COLUMN,
      DATE_CLOSED_COLUMN,
      FULL_PAYOFF_AMOUNT,
      RETURNED_AMOUNT_COLUMN,
      AMOUNT_PAID_TO_MIGHTY,
      AMOUNT_OWED_TO_COOP_COLUMN,
    ],
  },
};

export {
  FUNDING_COLUMNS,
  renderImportedLabel,
  renderSummedAmount,
  renderTotalLienCount,
  renderViewFunding,
  VANITY_ID_COLUMN,
};
