/* tslint:disable object-literal-sort-keys */
import React, { Component, Fragment } from 'react';
import { computed, isObservableArray, observable, toJS } from 'mobx';
import { observer, inject } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import Helmet from 'react-helmet';
import ReactTable from 'react-table';
import { browserHistory } from 'react-router';
import { has, get, pick, isEmpty, findIndex, orderBy } from 'lodash';

import {
  Button,
  Col,
  Row,
  Well,
} from 'react-bootstrap';

import {
  Icon,
  InfoWell,
  Loader,
  Page,
  StepProgress,
} from '../../common';

import ClientsClass from '../../../clients/ClientsClass';
import DataAdminHeader from '../DataAdminHeader';
import FormattingUtils from '../../../utils/FormattingUtils';
import SmartBool from '../../../utils/SmartBool';
import { AppConstants } from '../../../constants';
import { FormModal } from '../../../lib/mighty-fields';
import { SessionStoreClass } from '../../../stores';
import { getFilenameFromUrl } from '../../../utils/util';

import dataToList from './dataToList';
import { IMPORT_STEPS, PROGRESS_STEPS, TERMINAL_STEPS } from './Steps';
import { UPDATE_IMPORT, RESTART_IMPORT } from './FieldSets';

const { formatDateTime, varToLabel } = FormattingUtils;
const {
  IMPORT_CHECK_INTERVAL,
  MODEL_TYPES,
  PERMISSIONS,
  ROUTING,
} = AppConstants;

interface IProps {
  params: {
    id: string;
  };
}

interface IImport {
  configuration: string;
  configuration_label: string;
  created_at: string;
  created_by_user_name: string;
  error_file: string;
  id: string;
  input_file: string;
  label: string;
  last_status_update: {
    status: string;
  };
  rows_skipped: number;
}

interface IInjected extends IProps {
  Clients: ClientsClass;
  SessionStore: SessionStoreClass;
}

const PREVIEW_COLUMNS = ['data type', 'added', 'updated']
  , SECTION_MAPPING = {
    case_details: 'Case Details',
    lien_details: 'Lien Details',
  };

@inject('Clients', 'SessionStore')
@autoBindMethods
@observer
class ImportDetailPage extends Component<IProps> {
  @observable private importObj?: IImport;

  private isLoading = new SmartBool(true);
  private showEdit = new SmartBool();
  private showNewFile = new SmartBool();
  private isBulkEdit = new SmartBool(false);

  private pollInterval?: any;

  private get injected () {
    return this.props as IInjected;
  }

  public componentDidMount () {
    const location = browserHistory.getCurrentLocation();

    this.load();
    this.pollInterval = setInterval(this.poll, IMPORT_CHECK_INTERVAL);

    if (location.pathname.includes(ROUTING.bulkEdit)) {
      this.isBulkEdit.setTrue();
    }
  }

  // istanbul ignore next
  public componentWillUnmount () {
    if (this.pollInterval) {
      clearInterval(this.pollInterval);
    }
  }

  // istanbul ignore next
  private async poll () {
    const POLL_STATUS = ['QUEUED', 'PROGRESS']
      , currentStatus = this.currentStatus;

    if (!currentStatus) { return; }

    const statusSuffix = currentStatus.split('_').pop() || '';
    if (!POLL_STATUS.includes(statusSuffix)) { return; }

    this.load();
  }

  private async load () {
    const { params: { id } } = this.props;
    const response = await this.injected.Clients.imports.retrieve(id);
    this.importObj = response;
    this.isLoading.set(false);
  }

  private get currentStatus () {
    if (!this.importObj || !has(this.importObj, 'last_status_update.status')) {
      // istanbul ignore next
      return null;
    }

    return this.importObj.last_status_update.status;
  }

  private get nullFieldsData () {
    const nullFieldData = get(this.importObj, 'last_status_update.data.null_csv_fields', []);

    return nullFieldData;
  }

  private get previewData () {
    const testCommitPreview = get(this.importObj, 'last_status_update.data.preview', [])
      , finalPreview = get(this.importObj, 'preview_data.data.preview', []);

    return !!testCommitPreview.length ? testCommitPreview : finalPreview;
  }

  private get previewColumns () {
    return PREVIEW_COLUMNS.map(accessor => ({
      accessor,
      Header: varToLabel(accessor),
      minWidth: 200,
    }));
  }

  private async edit (data: object) {
    if (!this.importObj) { return; } // Only called when importObj is not null
    await this.injected.Clients.imports.updateForm(this.importObj.id, data);
    await this.load();
  }

  private async validate () {
    if (!this.importObj) { return; } // Only called when importObj is not null
    await this.injected.Clients.imports.validate(this.importObj.id);
    await this.load();
  }

  private async testCommit () {
    if (!this.importObj) { return; } // Only called when importObj is not null
    await this.injected.Clients.imports.testCommit(this.importObj.id);
    await this.load();
  }

  private async previewApprove () {
    if (!this.importObj) { return; } // Only called when importObj is not null
    await this.injected.Clients.imports.previewApprove(this.importObj.id);
    await this.load();
  }

  private async previewReject () {
    if (!this.importObj) { return; } // Only called when importObj is not null
    await this.injected.Clients.imports.previewReject(this.importObj.id);
    await this.load();
  }

  private async updateFile (data: object) {
    if (!this.importObj) { return; } // Only called when importObj is not null
    await this.injected.Clients.imports.updateForm(this.importObj.id, data);
    await this.load();
  }

  private async commit () {
    if (!this.importObj) { return; } // Only called when importObj is not null
    await this.injected.Clients.imports.commit(this.importObj.id);
    await this.load();
  }

  private async split () {
    if (!this.importObj) { return; } // Only called when importObj is not null
    await this.injected.Clients.imports.split(this.importObj.id);
    await this.load();
  }

  private renderValidationErrors () {
    const allData = get(this.importObj, 'last_status_update.data', {}),
      keys = ['validation_errors', 'template_error', 'error']
      , data = pick(allData, keys);

    if (isEmpty(data)) {
      return null;
    }

    return (
      <pre className='error-message'>
        {dataToList(data)}
      </pre>
    );
  }

  @computed
  private get stepsInfo () {
    const nullFieldsData = this.nullFieldsData
      , nullFields = nullFieldsData.length ? nullFieldsData.join(', ') : null;

    const failureMessage = (
      <Fragment>
        <p>You may upload a corrected data file using the button below.</p>
        <Button className='btn-new-file' bsSize='small' onClick={this.showNewFile.setTrue}>
          <Icon type='file-o' /> Upload new Data File
        </Button>
      </Fragment>
    );

    return {
      final_import: (
        <div>
          <p>Are you sure you want to import<br />this data into your Mighty account ?</p>
          <Button bsSize='small' bsStyle='primary' onClick={this.commit}>Proceed with Final Import</Button>
        </div>
      ),
      final_import_active: (
        <div className='info-loading'>
          <h5>Final Import in Progress...</h5>
          <div>(Based on the size of the import, this may take a while.)</div>
        </div>
      ),
      final_import_failed: (
        <div>
          <h5 className='text-danger'>Final Import Failed</h5>
          {failureMessage}
        </div>
      ),
      preview_approval: (
        <div>
          <p>This is a preview of the imported data. Review the information below,<br />then proceed to approve for further processing or reject to upload a new file.</p>
          {nullFields && (
            <p className='callout-note step'>
              <Icon type='exclamation-triangle' />{' '}
              <b>WARNING:</b> The following fields are empty in one or more rows: <b>{nullFields}</b>.<br />
              Approving this file may overwrite existing data with empty values for one or more cases. Please review carefully before proceeding.
            </p>
          )}
          <div>
            <Button bsSize='small' bsStyle='danger' onClick={this.previewReject}>Reject</Button>
            <Button bsSize='small' bsStyle='primary' onClick={this.previewApprove}>Approve</Button>
          </div>
        </div>
      ),
      preview_approval_failed: (
        <div>
          <h5 className='text-danger'>Preview Rejected</h5>
          {failureMessage}
        </div>
      ),
      test_import: (
        <div>
          <p>To ensure that the imported data is merged without errors,<br />we are going to create a temporary import for you to review.</p>
          <Button bsSize='small' bsStyle='primary' onClick={this.testCommit}>Create Test Import</Button>
        </div>
      ),
      test_import_active: (
        <div className='info-loading'>
          <h5>Test Import in Progress...</h5>
          <div>(Based on the size of the import, this may take a while.)</div>
        </div>
      ),
      test_import_failed: (
        <div>
          <h5 className='text-danger'>Test Import Failed</h5>
          {failureMessage}
        </div>
      ),
      validate_data: (
        <div>
          <p>Imported data requires validation.<br />Please click the button below to proceed.</p>
          <Button bsSize='small' bsStyle='primary' onClick={this.validate}>Validate Data</Button>
        </div>
      ),
      validate_data_active: (
        <div className='info-loading'>
          <h5>Data Validation in Progress...</h5>
          <div>(Based on the size of the import, this may take a while.)</div>
        </div>
      ),
      validate_data_failed: (
        <div>
          <h5 className='text-danger'>Data Validation Failed</h5>
          {failureMessage}
          {this.renderValidationErrors()}
        </div>
      ),
      validate_length_failed: (
        <div>
          <h5 className='text-danger'>Data Validation Failed</h5>
          <p>The file is longer than permitted. You may split the file into multiple imports automatically.</p>
          <Button className='btn-split' bsSize='small' bsStyle='primary' onClick={this.split}>Split</Button>
        </div>
      ),
      revert_active: (
        <div className='info-loading'>
          <h5>Revert in Progress...</h5>
          <div>(Based on the size of the import, this may take a while.)</div>
        </div>
      ),
      reverted: (
        <div>
          <h5>Reverted</h5>
          <div>Any objects created by this import have been removed.</div>
        </div>
      ),
      revert_failed: (
        <div className='text-danger'>
          <h5>Revert Failed</h5>
          <div>Some data may not have been reverted. Please contact Mighty support.</div>
        </div>
      ),
      split_active: (
        <div className='info-loading'>
          <h5>Split in Progress...</h5>
          <div>(Based on the size of the import, this may take a while.)</div>
        </div>
      ),
      split: (
        <div>
          <h5>Import Split</h5>
          <div>Return to the main imports page to run the new imports.</div>
        </div>
      ),
      split_failed: (
        <div className='text-danger'>
          <h5>Split Failed</h5>
          {failureMessage}
        </div>
      ),
    } as any;
  }

  private onDownloadDataFile () {
    if (!this.importObj) { return; } // Only called when importObj is not null
    this.injected.Clients.imports.getDocument(this.importObj.input_file);
  }

  private onDownloadErrorFile () {
    if (!this.importObj) { return; }
    this.injected.Clients.imports.getDocument(this.importObj.error_file);
  }

  private renderInfo () {
    if (!this.importObj) { return; } // Only called when importObj is not null
    const COL_WIDTHS = { template: 2, file: 4, createdAt: 2, actions: 1, importedBy: 2}
      , showEditButton = !this.currentStatus || !TERMINAL_STEPS.includes(this.currentStatus);

    return (
      <Well className='import-info center-block maxwidth-lg'>
        <h3>{this.importObj.label}</h3>
        <InfoWell>
          <Row>
            <Col className='col-label' xs={COL_WIDTHS.template}>Template</Col>
            <Col className='col-label' xs={COL_WIDTHS.file}>File</Col>
            <Col className='col-label' xs={COL_WIDTHS.createdAt}>Created at</Col>
            {showEditButton &&
              <Col className='col-label' xs={COL_WIDTHS.actions}>Actions</Col>
            }
            <Col className='col-label' xs={COL_WIDTHS.importedBy}>Imported By</Col>
          </Row>
          <Row>
            <Col className='col-value' xs={COL_WIDTHS.template}>{this.importObj.configuration_label}</Col>
            <Col className='col-value' xs={COL_WIDTHS.file}>
              <a onClick={this.onDownloadDataFile}>../{getFilenameFromUrl(this.importObj.input_file)}</a>
            </Col>
            <Col className='col-value' xs={COL_WIDTHS.createdAt}>{formatDateTime(this.importObj.created_at)}</Col>
            {showEditButton &&
              <Col className='col-value col-actions' xs={COL_WIDTHS.actions}>
                <Button bsStyle='link' onClick={this.showEdit.setTrue}><Icon type='pencil' /></Button>
              </Col>
            }
            <Col className='col-value' xs={COL_WIDTHS.template}>{this.importObj.created_by_user_name}</Col>
          </Row>
        </InfoWell>
      </Well>
    );
  }

  private renderPreview () {
    const currentStatus = this.currentStatus && IMPORT_STEPS[this.currentStatus]
      , eligibleStatus = this.currentStatus === 'PASSED_TEST_COMMIT' || currentStatus.complete;

    if (!eligibleStatus || !this.previewData || this.previewData.length === 0) {
      return;
    }
    const isDataValid = this.previewData.every((section: any) => {
      const data = Object.values(section)[0];

      if (section['null_csv_fields']) {
        return true;
      }

      return isObservableArray(data) && data.every((row: any) => {
        return PREVIEW_COLUMNS.every(key => key in row);
      });
    });

    if (!isDataValid) {
      return (
        <Well className='well-preview'>
          <div>Preview not available</div>
        </Well>
      );
    }

    return (
      <div>
        <Well className='well-preview'>
          <h3>Import Summary</h3>
          {this.previewData.map((section: string, index: number) => {
            const sectionName = Object.keys(section)
              , data = get(section, sectionName[0], [])
              , sortedData = orderBy(data, ['added'], ['desc'])
              , sectionTitle = get(SECTION_MAPPING, sectionName, '');

            if (!sectionTitle) {
              return;
            }

            return (
              <Well key={index} className='well-preview'>
                <h3>{sectionTitle}</h3>
                <div className='table-preview'>
                  <ReactTable
                    columns={this.previewColumns}
                    data={toJS(sortedData)}
                    minRows={0}
                    pageSize={sortedData.length}
                    resizable={false}
                    showPagination={false}
                  />
                </div>
              </Well>
            );
          })}
        </Well>
      </div>
    );
  }

  private renderLinkToDedupe () {
    const { SessionStore } = this.injected;

    if (!this.importObj || !SessionStore.userHasPermission(PERMISSIONS.CHANGE_DEDUPE)) {
      // istanbul ignore next
      return null;
    }

    return (
      <div>
        Deduplicate objects related to this import
        {' '}<a href={`/data-admin/deduplication/${MODEL_TYPES.case.key}?from_import=${this.importObj.id}`}>here</a>
      </div>
    );
  }

  private renderProgress () {
    if (!this.currentStatus) { return; }

    const currentStatus = IMPORT_STEPS[this.currentStatus]
      , progressIndex: number = findIndex(PROGRESS_STEPS, { key: currentStatus.progressStep });

    // Import has completed successfully, hide progress, show confirmation display
    if (currentStatus.complete) {
      return (
        <Well className='import-complete center-block maxwidth-lg'>
          <div className='complete'>
            <Icon type='check-circle' />
            <h5>Import Complete</h5>
            {!this.isBulkEdit.isTrue && this.renderLinkToDedupe()}
          </div>
        </Well>
      );
    }

    // Import has reached a terminal state other than a successful commit (split or reverted)
    if (!currentStatus.complete && TERMINAL_STEPS.includes(this.currentStatus)) {
      return (
        <Well className='import-progress center-block maxwidth-lg'>
          <div className='step-info'>
            {this.stepsInfo[currentStatus.progressInfo]}
          </div>
        </Well>
      );
    }

    // Import in progress, show the progress and steps info
    return (
      <Well className='import-progress center-block maxwidth-lg'>
        <StepProgress
          active={currentStatus.active}
          bsStyle={currentStatus.style}
          currentStep={progressIndex}
          steps={PROGRESS_STEPS}
        />
        <div className='step-info'>
          {this.stepsInfo[currentStatus.progressInfo]}
        </div>
      </Well>
    );
  }

  private renderValidationWarning () {
    if (!this.importObj || !this.importObj?.error_file) { return; }

    return (
      <Well className='import-validation-warning center-block maxwidth-lg'>
        <div>
          <Icon type='exclamation-triangle' />
          <b>There were {this.importObj.rows_skipped} rows that failed validation and are excluded from this import.</b>
          <Icon type='exclamation-triangle' />
          <p>
            To import these records:<br/>
            <div className='error-instructions'>
              1. Download the error file below.<br/>
              2. Correct the issues in each row.<br/>
              3. Re-import the corrected rows. Remember to delete the 'Errors' column.
            </div>
            <div className='error-file'>Error File</div>
            <a onClick={this.onDownloadErrorFile}>
              <Icon type='download'/>
              <span>
                ../{getFilenameFromUrl(this.importObj.error_file)}
              </span>
            </a>
          </p>
        </div>
      </Well>
    );
  }

  private renderModals () {
    const editNote = <div className='info'>Note: Modifying an import will reset it at the first step.</div>;

    return (
      <React.Fragment>
        {this.showNewFile.isTrue && (
          <FormModal
            fieldSets={RESTART_IMPORT}
            onClose={this.showNewFile.setFalse}
            onSave={this.updateFile}
            title='New File'
          />
        )}

        {this.showEdit.isTrue && (
          <FormModal
            className='modal-import-edit'
            childrenAfter={editNote}
            fieldSets={UPDATE_IMPORT}
            model={this.importObj}
            onClose={this.showEdit.setFalse}
            onSave={this.edit}
            title='Edit'
          />
        )}

      </React.Fragment>
    );
  }

  public render () {
    const title = this.isBulkEdit.isTrue ? 'Bulk Edit' : 'Bulk Add';

    if (!this.importObj) {
      return (
        <Page name='imports' type='detail'>
          <Helmet title={`Data Admin - ${title} - Loading...`} />
          <Loader className='page-loader' logo />
        </Page>
      );
    }

    return (
      <Page name='imports' type='detail'>
        <Helmet title={`Data Admin - ${title} - ${this.importObj.label}`} />
        <Page.Content>
          <DataAdminHeader title={title} />
          <div className='main'>
            <Row>
              <Col className='col-main' xs={12}>
                {this.renderInfo()}
                {this.renderProgress()}
                {this.renderValidationWarning()}
                {this.renderPreview()}
                {this.renderModals()}
              </Col>
            </Row>
          </div>
        </Page.Content>
      </Page>
    );
  }
}

export default ImportDetailPage;
