/* tslint:disable object-literal-sort-keys */
import { FormattingUtils, TableRenderingUtils } from '../../utils';

const {
  formatCommaSeparatedNumber,
  formatMoney,
} = FormattingUtils;

const {
  renderBlankCell,
  renderBooleanYesNo,
  renderBooleanArray,
  renderDate,
  renderHeaderWithTooltip,
  renderIsOpen,
  renderMoney,
  renderMoneyArrayCell,
  renderPercentage,
  renderPhoneNumber,
  renderPhoneNumberArrayCell,
  renderRegistrySharingEligibilityStatuses,
  renderSnakeCaseCell,
  renderStartCasedArrayCell,
  renderStatusCell,
  renderUnformatted,
  renderUnformattedArrayCell,
} = TableRenderingUtils;

const HeaderDaysSinceFunding = renderHeaderWithTooltip(
    'reports-days-since-fundings',
    'Days Since Funding',
    'Days Since Funding is the time between today and Agreement Date',
  )
  , HeaderDaysSinceAcquisition = renderHeaderWithTooltip(
    'reports-days-since-acquisition',
    'Days Since Acquisition',
    'Days Since Acquisition is the time between today and Acquisition Date',
  )
  , HeaderDaysToReturn = renderHeaderWithTooltip(
    'reports-days-to-return',
    'Days To Return',
    'Days to return is the time between Date Matured and Agreement Date',
  )
  , HeaderROI = renderHeaderWithTooltip(
    'reports-roi',
    'ROI',
    'ROI % is calculated as (Gross Profit / Principal Amount)',
  )
  , HeaderAnnualizedROI = renderHeaderWithTooltip(
    'reports-annualized-roi',
    'Annualized ROI',
    'Annualized ROI % if calculated as (Gross Profit * (365 / Days to Return))',
  )
  , HeaderCurrentContractValue = renderHeaderWithTooltip(
    'current-contract-value',
    'Current Contract Value',
    'Current Contract Value is the current lien balance according to the contract terms',
  )
  , HeaderGrossProfit = renderHeaderWithTooltip(
    'reports-gross-profit',
    'Gross Profit',
    'Gross Profit is calculated is the Returned amount - Principal amount',
  )
  , HeaderPrincipalAmount = renderHeaderWithTooltip(
    'reports-principal-amount',
    'Principal Amount',
    'Principal Amount is the lien amount + any fees on which interest accrues',
  )
  , HeaderLienAmount = renderHeaderWithTooltip(
    'reports-lien-amount',
    'Lien Amount',
    'Lien Amount is the Amount to Plaintiff + Buyout (if applicable)',
  )
  , HeaderCurrentLienBalance = renderHeaderWithTooltip(
    'reports-current-lien-balance',
    'Current Lien Balance',
    'Current Lien Balance is the Current Contract Value minus Returned Amount',
  );

const colStickyClass = '-col-sticky'
  , CASE_VANITY_ID_CELL = [
    {
      Header: '',
      accessor: 'blank',
      width: 300,
      sortable: false,
      Cell: renderBlankCell,
    },
    {
      Header: 'Case Id',
      accessor: 'case_id',
      maxWidth: 110,
      minWidth: 110,
      sortable: false,
    },
  ]
  , LIEN_DATE_CLOSED_COLUMN = {
    Header: 'Date Closed',
    accessor: 'date_closed',
    minWidth: 150,
    Cell: renderDate,
  }
  , LATEST_DATE_CLOSED_COLUMN = {
    Header: 'Latest Date Closed',
    accessor: 'latest_date_closed',
    minWidth: 190,
    Cell: renderDate,
  }
  , AMT_OWED_TO_MIGHTY_COLUMN = {
    Header: 'Amt Owed To Mighty',
    accessor: 'amt_owed_to_mighty',
    isFinanceColumn: true,
    minWidth: 150,
    sortable: false,
    Cell: renderMoney,
  }
  , LIEN_FEES_COLUMN_GROUP = [
    {
      Header: 'Flat Application Fee',
      accessor: 'total_flat_application_fee',
      minWidth: 180,
      Cell: renderMoney,
    },
    {
      Header: 'Principal Application Fee',
      accessor: 'total_principal_application_fee',
      minWidth: 200,
      Cell: renderMoney,
    },
    {
      Header: 'Flat Processing Fee',
      accessor: 'total_flat_processing_fee',
      minWidth: 180,
      Cell: renderMoney,
    },
    {
      Header: 'Principal Processing Fee',
      accessor: 'total_principal_processing_fee',
      minWidth: 200,
      Cell: renderMoney,
    },
    {
      Header: 'Flat Attorney Review Fee',
      accessor: 'total_flat_attorney_review_fee',
      minWidth: 200,
      Cell: renderMoney,
    },
    {
      Header: 'Principal Attorney Review Fee',
      accessor: 'total_principal_attorney_review_fee',
      minWidth: 230,
      Cell: renderMoney,
    },
    {
      Header: 'Flat Broker Fee',
      accessor: 'total_flat_broker_fee',
      minWidth: 180,
      Cell: renderMoney,
    },
    {
      Header: 'Principal Broker Fee',
      accessor: 'total_principal_broker_fee',
      minWidth: 180,
      Cell: renderMoney,
    },
    {
      Header: 'Flat Other Fee',
      accessor: 'total_flat_other_fee',
      minWidth: 180,
      Cell: renderMoney,
    },
    {
      Header: 'Principal Other Fee',
      accessor: 'total_principal_other_fee',
      minWidth: 180,
      Cell: renderMoney,
    },
  ]
  , LIEN_FUNDING_COLUMN_GROUP = [
    {
      Header: 'Lien ID',
      accessor: 'lien_id',
      minWidth: 150,
      sortable: true,
      Cell: renderUnformatted,
    },
    {
      Header: 'Funded Date',
      accessor: 'funded_date',
      minWidth: 150,
      Cell: renderDate,
    },
    {
      Header: 'Agreement Date',
      accessor: 'agreement_date',
      minWidth: 150,
      Cell: renderDate,
    },
    {
      Header: 'Interest Start Date',
      accessor: 'interest_start_date',
      minWidth: 180,
      Cell: renderDate,
    },
    {
      Header: HeaderDaysSinceFunding,
      accessor: 'days_since_funding',
      minWidth: 180,
      sortable: false,
      Cell: renderUnformatted,
    },
    {
      Header: HeaderLienAmount,
      accessor: 'funded_amt',
      minWidth: 150,
      sortable: false,
      Cell: renderMoney,
    },
    {
      Header: HeaderPrincipalAmount,
      accessor: 'principal_amt',
      minWidth: 150,
      sortable: false,
      Cell: renderMoney,
    },
    {
      Header: HeaderCurrentContractValue,
      accessor: 'current_contract_value',
      minWidth: 220,
      Cell: renderMoney,
    },
    {
      Header: HeaderCurrentLienBalance,
      accessor: 'current_payoff',
      minWidth: 190,
      Cell: renderMoney,
    },
    LATEST_DATE_CLOSED_COLUMN,
    {
      Header: 'Capital Providers',
      accessor: 'capital_providers',
      minWidth: 150,
      sortable: false,
      Cell: renderUnformattedArrayCell,
    },
    {
      Header: 'Term Template',
      accessor: 'term_template',
      minWidth: 150,
      sortable: false,
      Cell: renderUnformatted,
    },
    LIEN_DATE_CLOSED_COLUMN,
    ...LIEN_FEES_COLUMN_GROUP,
  ]
  , CASE_TRACKING_COLUMN_GROUP = [
    {
      Header: 'Case Tracking Status',
      accessor: 'case_tracking_status',
      minWidth: 200,
      sortable: false,
      Cell: renderStatusCell,
    },
    {
      Header: 'Medical Status',
      accessor: 'medical_status',
      minWidth: 200,
      sortable: false,
      Cell: renderStatusCell,
    },
    {
      Header: 'Case Tracking Attributes',
      accessor: 'case_tracking_attributes',
      minWidth: 200,
      sortable: false,
      Cell: renderStartCasedArrayCell,
    },
    {
      Header: 'Case Return Status',
      accessor: 'case_return_status',
      minWidth: 200,
      sortable: false,
      Cell: renderStatusCell,
    },
    {
      Header: 'Last Case Tracking Date',
      accessor: 'last_case_tracking_date',
      minWidth: 200,
      sortable: true,
      Cell: renderDate,
    },
    {
      Header: 'Last Case Tracking Note',
      accessor: 'last_case_tracking_note',
      minWidth: 200,
      sortable: false,
      Cell: renderUnformatted,
    },
    {
      Header: 'Case Tracking Follow Up Date',
      accessor: 'case_tracking_follow_up_date',
      minWidth: 240,
      sortable: true,
      Cell: renderDate,
    },
    {
      Header: 'Case Tracking Contact',
      accessor: 'tracking_contact',
      minWidth: 200,
      sortable: false,
      Cell: renderUnformatted,
    },
    {
      Header: 'Case Tracking Contact Email',
      accessor: 'tracking_contact_email',
      minWidth: 150,
      sortable: false,
      Cell: renderUnformatted,
    },
    {
      Header: 'Last Case Note',
      accessor: 'last_case_note_body',
      minWidth: 200,
      sortable: false,
      Cell: renderUnformatted,
    },
    {
      Header: 'Last Case Note Date',
      accessor: 'last_case_note_date',
      minWidth: 200,
      sortable: true,
      Cell: renderDate,
    },
    {
      Header: 'Case Contacts',
      accessor: 'case_contacts',
      minWidth: 200,
      sortable: false,
      Cell: renderUnformattedArrayCell,
    },
    {
      Header: 'Case Contact Phone Number(s)',
      accessor: 'case_contact_phone_numbers',
      minWidth: 240,
      sortable: false,
      Cell: renderPhoneNumberArrayCell,
    },
    {
      Header: 'Case Contact Email(s)',
      accessor: 'case_contact_emails',
      minWidth: 200,
      sortable: false,
      Cell: renderUnformattedArrayCell,
    },
    {
      Header: 'Liens Acknowledged',
      accessor: 'liens_acknowledged',
      minWidth: 180,
      sortable: false,
      Cell: renderBooleanYesNo,
    },
    {
      Header: 'Law Firm Portal Eligibility',
      accessor: 'registry_sharing_eligibility',
      minWidth: 240,
      sortable: false,
      Cell: renderRegistrySharingEligibilityStatuses,
    },
  ]
  , LIEN_CASE_TRACKING_COLUMN_GROUP = [
    {
      Header: 'Case Tracking Status',
      accessor: 'case_tracking_status',
      minWidth: 200,
      sortable: false,
      Cell: renderStatusCell,
    },
    {
      Header: 'Medical Status',
      accessor: 'medical_status',
      minWidth: 200,
      sortable: false,
      Cell: renderStatusCell,
    },
    {
      Header: 'Case Tracking Attributes',
      accessor: 'case_tracking_attributes',
      minWidth: 200,
      sortable: false,
      Cell: renderStartCasedArrayCell,
    },
    {
      Header: 'Last Case Tracking Date',
      accessor: 'last_case_tracking_date',
      minWidth: 200,
      sortable: true,
      Cell: renderDate,
    },
    {
      Header: 'Last Case Tracking Note',
      accessor: 'last_case_tracking_note',
      minWidth: 200,
      sortable: false,
      Cell: renderUnformatted,
    },
    {
      Header: 'Case Tracking Follow Up Date',
      accessor: 'case_tracking_follow_up_date',
      minWidth: 240,
      sortable: true,
      Cell: renderDate,
    },
    {
      Header: 'Case Tracking Contact',
      accessor: 'tracking_contact',
      minWidth: 200,
      sortable: false,
      Cell: renderUnformatted,
    },
    {
      Header: 'Case Tracking Contact Email',
      accessor: 'tracking_contact_email',
      minWidth: 150,
      sortable: false,
      Cell: renderUnformatted,
    },
    {
      Header: 'Last Case Note',
      accessor: 'last_case_note_body',
      minWidth: 200,
      sortable: false,
      Cell: renderUnformatted,
    },
    {
      Header: 'Last Case Note Date',
      accessor: 'last_case_note_date',
      minWidth: 200,
      sortable: true,
      Cell: renderDate,
    },
    {
      Header: 'Case Contacts',
      accessor: 'case_contacts',
      minWidth: 200,
      sortable: false,
      Cell: renderUnformattedArrayCell,
    },
    {
      Header: 'Case Contact Phone Number(s)',
      accessor: 'case_contact_phone_numbers',
      minWidth: 240,
      sortable: false,
      Cell: renderPhoneNumberArrayCell,
    },
    {
      Header: 'Case Contact Email(s)',
      accessor: 'case_contact_emails',
      minWidth: 200,
      sortable: false,
      Cell: renderUnformattedArrayCell,
    },
  ]
  , CASE_PLAINTIFF_FUNDING_COLUMN_GROUP = [
    {
      Header: 'Earliest Agreement Date',
      accessor: 'earliest_agreement_date',
      minWidth: 200,
      Cell: renderDate,
    },
    {
      Header: 'Latest Agreement Date',
      accessor: 'latest_agreement_date',
      minWidth: 200,
      Cell: renderDate,
    },
    {
      Header: '# of Liens',
      accessor: 'number_of_fundings',
      minWidth: 130,
      sortable: false,
      Cell: renderUnformatted,
    },
    {
      Header: 'Lien Amt',
      accessor: 'funded_amt',
      minWidth: 150,
      sortable: false,
      Cell: renderMoney,
    },
    {
      Header: 'Principal Amt',
      accessor: 'principal_amt',
      minWidth: 150,
      sortable: false,
      Cell: renderMoney,
    },
    {
      Header: 'Current Contract Value',
      accessor: 'current_contract_value',
      minWidth: 200,
      Cell: renderMoney,
    },
    {
      Header: 'Current Lien Balance',
      accessor: 'current_payoff',
      minWidth: 190,
      Cell: renderMoney,
    },
    LATEST_DATE_CLOSED_COLUMN,
  ]
  , CASE_MEDICAL_FUNDING_COLUMN_GROUP = [
    {
      Header: 'Earliest Purchase Date',
      accessor: 'earliest_purchase_date',
      minWidth: 200,
      Cell: renderDate,
    },
    {
      Header: 'Latest Purchase Date',
      accessor: 'latest_purchase_date',
      minWidth: 200,
      Cell: renderDate,
    },
    {
      Header: '# of Liens',
      accessor: 'number_of_fundings',
      minWidth: 130,
      sortable: false,
      Cell: renderUnformatted,
    },
    {
      Header: 'Purchase Amt.',
      accessor: 'purchase_amt',
      minWidth: 150,
      sortable: false,
      Cell: renderMoney,
    },
    {
      Header: 'Billing Amt.',
      accessor: 'billing_amt',
      minWidth: 150,
      sortable: false,
      Cell: renderMoney,
    },
    {
      Header: 'Remaining Billing Amt',
      accessor: 'remaining_billing_amt',
      minWidth: 200,
      sortable: true,
      Cell: renderMoney,
    },
    {
      Header: 'Reduction Percentage',
      accessor: 'reduction_percentage',
      minWidth: 190,
      Cell: renderPercentage,
    },
    LATEST_DATE_CLOSED_COLUMN,
  ]
  , LIEN_RETURN_COLUMN_GROUP = [
    {
      Header: 'Latest Maturity Date',
      accessor: 'date_matured',
      minWidth: 200,
      Cell: renderDate,
    },
    {
      Header: HeaderDaysToReturn,
      accessor: 'days_to_return',
      minWidth: 160,
      sortable: false,
      Cell: renderUnformatted,
    },
    AMT_OWED_TO_MIGHTY_COLUMN,
    {
      Header: 'Expected Amt',
      accessor: 'expected_amt',
      minWidth: 150,
      sortable: false,
      Cell: renderMoney,
    },
    {
      Header: 'Returned Amt',
      accessor: 'returned_amt',
      minWidth: 150,
      sortable: false,
      Cell: renderMoney,
    },
    {
      Header: HeaderGrossProfit,
      accessor: 'gross_profit',
      minWidth: 150,
      sortable: false,
      Cell: renderMoney,
    },
    {
      Header: HeaderROI,
      accessor: 'roi',
      minWidth: 110,
      sortable: false,
      Cell: renderPercentage,
    },
    {
      Header: HeaderAnnualizedROI,
      accessor: 'annualized_roi',
      minWidth: 160,
      Cell: renderPercentage,
    },
  ]
  , CASE_RETURN_COLUMN_GROUP = [
    {
      Header: 'Latest Maturity Date',
      accessor: 'latest_maturity_date',
      minWidth: 200,
      Cell: renderDate,
    },
    AMT_OWED_TO_MIGHTY_COLUMN,
    {
      Header: 'Expected Amt',
      accessor: 'expected_amt',
      minWidth: 150,
      sortable: false,
      Cell: renderMoney,
    },
    {
      Header: 'Returned Amt',
      accessor: 'returned_amt',
      minWidth: 150,
      sortable: false,
      Cell: renderMoney,
    },
    {
      Header: 'Gross Profit',
      accessor: 'gross_profit',
      minWidth: 150,
      sortable: false,
      Cell: renderMoney,
    },
    {
      Header: HeaderROI,
      accessor: 'roi',
      minWidth: 110,
      sortable: false,
      Cell: renderPercentage,
    },
  ]
  , UNDERWRITING_COLUMN_GROUP = [
    {
      Header: 'Est. Case Value',
      accessor: 'estimated_case_value',
      minWidth: 150,
      sortable: false,
      Cell: renderMoney,
    },
    {
      Header: 'Expected Settlement Date',
      accessor: 'expected_settlement_date',
      minWidth: 220,
      sortable: false,
      Cell: renderDate,
    },
    {
      Header: 'Payoff @ Expected Settlement Date',
      accessor: 'payoff_at_expected_settlement_date',
      minWidth: 270,
      sortable: false,
      Cell: renderMoney,
    },
  ]
  , CASE_INFO_COLUMN_GROUP = [
    {
      Header: 'Attorney',
      accessor: 'attorney',
      minWidth: 150,
      sortable: false,
      Cell: renderUnformatted,
    },
    {
      Header: 'Attorney Phone',
      accessor: 'attorney_phone_number',
      minWidth: 150,
      sortable: false,
      Cell: renderPhoneNumber,
    },
    {
      Header: 'Attorney Email',
      accessor: 'attorney_email',
      minWidth: 150,
      sortable: false,
      Cell: renderUnformatted,
    },
    {
      Header: 'Law Firm',
      accessor: 'lawfirm',
      minWidth: 150,
      sortable: false,
      Cell: renderUnformatted,
    },
    {
      Header: 'Law Firm Address',
      accessor: 'lawfirm_address',
      minWidth: 190,
      sortable: false,
      Cell: renderUnformatted,
    },
    {
      Header: 'Law Firm City',
      accessor: 'lawfirm_city',
      minWidth: 150,
      sortable: false,
      Cell: renderUnformatted,
    },
    {
      Header: 'Law Firm State',
      accessor: 'lawfirm_state',
      minWidth: 150,
      sortable: false,
      Cell: renderUnformatted,
    },
    {
      Header: 'Law Firm ZIP Code',
      accessor: 'lawfirm_zip',
      minWidth: 150,
      sortable: false,
      Cell: renderUnformatted,
    },
    {
      Header: 'Law Firm Phone',
      accessor: 'lawfirm_phone_number',
      minWidth: 150,
      sortable: false,
      Cell: renderPhoneNumber,
    },
    {
      Header: 'Case Type',
      accessor: 'case_type',
      minWidth: 150,
      sortable: false,
      Cell: renderUnformatted,
    },
    {
      Header: 'Defendant',
      accessor: 'defendant',
      minWidth: 150,
      sortable: false,
      Cell: renderUnformatted,
    },
    {
      Header: 'Defendant Insurers',
      accessor: 'defendant_insurers',
      minWidth: 150,
      sortable: false,
      Cell: renderUnformattedArrayCell,
    },
    {
      Header: 'Plaintiff Insurer',
      accessor: 'plaintiff_insurer',
      minWidth: 150,
      sortable: false,
      Cell: renderUnformatted,
    },
    {
      Header: 'State of Incident',
      accessor: 'state_of_incident',
      minWidth: 150,
      sortable: false,
      Cell: renderUnformatted,
    },
    {
      Header: 'Date of Loss',
      accessor: 'date_of_loss',
      minWidth: 150,
      sortable: false,
      Cell: renderDate,
    },
    {
      Header: 'Offer Amount',
      accessor: 'offer_amount',
      minWidth: 150,
      sortable: false,
      Cell: renderMoneyArrayCell,
    },
    {
      Header: 'Offer Accepted',
      accessor: 'offer_accepted',
      minWidth: 150,
      sortable: false,
      Cell: renderBooleanArray,
    },
    {
      Header: 'Settlement Amount',
      accessor: 'settlement_amount',
      minWidth: 270,
      sortable: false,
      Cell: renderMoney,
    },
    {
      Header: 'Settlement Date',
      accessor: 'settlement_date',
      minWidth: 220,
      sortable: false,
      Cell: renderDate,
    },
    {
      Header: 'Reduction Percentage',
      accessor: 'reduction_percentage',
      minWidth: 220,
      sortable: false,
      Cell: renderPercentage,
    },
    {
      Header: 'Court Index Number',
      accessor: 'court_index_number',
      minWidth: 180,
      sortable: false,
      Cell: renderUnformatted,
    },
  ]
  , PLAINTIFF_INFO_COLUMN_GROUP = [
    {
      Header: 'Plaintiff Date of Birth',
      accessor: 'plaintiff_birthdate',
      className: 'fs-mask',
      minWidth: 170,
      sortable: false,
      Cell: renderDate,
    },
    {
      Header: 'Plaintiff Phone',
      accessor: 'plaintiff_phone_number',
      minWidth: 150,
      sortable: false,
      Cell: renderPhoneNumber,
    },
    {
      Header: 'Plaintiff Email',
      accessor: 'plaintiff_email',
      minWidth: 150,
      sortable: false,
      Cell: renderUnformatted,
    },
    {
      Header: 'Plaintiff Address',
      accessor: 'plaintiff_address',
      minWidth: 150,
      sortable: false,
      Cell: renderUnformatted,
    },
    {
      Header: 'Plaintiff City',
      accessor: 'plaintiff_city',
      minWidth: 150,
      sortable: false,
      Cell: renderUnformatted,
    },
    {
      Header: 'Plaintiff State',
      accessor: 'plaintiff_state',
      minWidth: 150,
      sortable: false,
      Cell: renderUnformatted,
    },
    {
      Header: 'Plaintiff ZIP Code',
      accessor: 'plaintiff_zip',
      minWidth: 150,
      sortable: false,
      Cell: renderUnformatted,
    },
    {
      Header: 'Plaintiff Full Name',
      accessor: 'plaintiff_name',
      minWidth: 150,
      sortable: false,
      Cell: renderUnformatted,
    },
  ]
  , LIEN_APPLICATION_COLUMN_GROUP = [
    {
      Header: 'Application Status',
      accessor: 'application_status',
      className: 'capitalize',
      minWidth: 160,
      sortable: false,
      Cell: renderUnformatted,
    },
    {
      Header: 'Application Created At',
      accessor: 'application_created_at',
      minWidth: 190,
      sortable: true,
      Cell: renderDate,
    },
    {
      Header: 'Amount Requested',
      accessor: 'requested_amt',
      minWidth: 170,
      sortable: true,
      Cell: renderMoney,
    },
  ]
  , MEDICAL_APPLICATION_COLUMN_GROUP = [
    {
      Header: 'Application Status',
      accessor: 'application_status',
      className: 'capitalize',
      minWidth: 160,
      sortable: false,
      Cell: renderUnformatted,
    },
    {
      Header: 'Application Created At',
      accessor: 'application_created_at',
      minWidth: 190,
      sortable: true,
      Cell: renderDate,
    },
  ]
  , CASE_REP_COLUMN_GROUP = [
    {
      Header: 'Primary Rep.',
      accessor: 'primary_rep',
      minWidth: 150,
      sortable: false,
      Cell: renderUnformatted,
    },
    {
      Header: 'Secondary Rep.',
      accessor: 'secondary_rep',
      minWidth: 150,
      sortable: false,
      Cell: renderUnformatted,
    },
    {
      Header: 'Tertiary Rep.',
      accessor: 'tertiary_rep',
      minWidth: 150,
      sortable: false,
      Cell: renderUnformatted,
    },
  ]
  , BUYOUT_COLUMN_GROUP = [
    {
      Header: 'Buyout Funder Name',
      accessor: 'buyout_funder_name',
      minWidth: 150,
      sortable: false,
      Cell: renderUnformatted,
    },
    {
      Header: 'Buyout Amount',
      accessor: 'buyout_amount',
      minWidth: 150,
      sortable: false,
      Cell: renderUnformatted,
    },
    {
      Header: 'Buyout Expiration Date',
      accessor: 'buyout_expiration_date',
      minWidth: 180,
      sortable: false,
      Cell: renderDate,
    },
  ]
  , LEAD_SOURCE_COLUMN_GROUP = [
    {
      Header: 'Lead Source Category',
      accessor: 'lead_source_category',
      minWidth: 200,
      sortable: false,
      Cell: renderSnakeCaseCell,
    },
    {
      Header: 'Lead Source Name',
      accessor: 'lead_source_name',
      minWidth: 200,
      sortable: false,
      Cell: renderUnformatted,
    },
  ]
  , MEDICAL_LIEN_FUNDING_COLUMN_GROUP = [
    {
      Header: 'Lien ID',
      accessor: 'lien_id',
      minWidth: 150,
      sortable: true,
      Cell: renderUnformatted,
    },
    {
      Header: 'Purchase Date',
      accessor: 'purchase_date',
      minWidth: 150,
      sortable: true,
      Cell: renderDate,
    },
    {
      Header: 'Days Since Purchase',
      accessor: 'days_since_purchase',
      minWidth: 170,
      sortable: false,
      Cell: renderUnformatted,
    },
    {
      Header: 'Purchase Amt',
      accessor: 'purchase_amt',
      minWidth: 150,
      sortable: false,
      Cell: renderMoney,
    },
    {
      Header: 'Billing Amt',
      accessor: 'billing_amt',
      minWidth: 150,
      sortable: true,
      Cell: renderMoney,
    },
    {
      Header: 'Remaining Billing Amt',
      accessor: 'remaining_billing_amt',
      minWidth: 200,
      sortable: true,
      Cell: renderMoney,
    },
    {
      Header: 'Reduction Percentage',
      accessor: 'reduction_percentage',
      minWidth: 190,
      Cell: renderPercentage,
    },
    {
      Header: 'Capital Providers',
      accessor: 'capital_providers',
      minWidth: 150,
      sortable: false,
      Cell: renderUnformattedArrayCell,
    },
    LIEN_DATE_CLOSED_COLUMN,
  ]
  , MEDICAL_PROCEDURE_COLUMN_GROUP = [
    {
      Header: 'Label',
      accessor: 'label',
      minWidth: 100,
      sortable: false,
      Cell: renderUnformatted,
    },
    {
      Header: 'Initial Service Date',
      accessor: 'initial_service_date',
      minWidth: 150,
      sortable: true,
      Cell: renderDate,
    },
    {
      Header: 'End Service Date',
      accessor: 'end_service_date',
      minWidth: 150,
      sortable: true,
      Cell: renderDate,
    },
    {
      Header: 'Medical Provider',
      accessor: 'medical_provider',
      minWidth: 170,
      sortable: false,
      Cell: renderUnformatted,
    },
    {
      Header: 'Medical Facility Contact',
      accessor: 'medical_facility_contact',
      minWidth: 170,
      sortable: false,
      Cell: renderUnformatted,
    },
    {
      Header: 'Medical Facility',
      accessor: 'medical_facility',
      minWidth: 170,
      sortable: false,
      Cell: renderUnformatted,
    },
    {
      Header: 'Medical Facility Address',
      accessor: 'medical_facility_address',
      minWidth: 190,
      sortable: false,
      Cell: renderUnformatted,
    },
    {
      Header: 'Medical Facility City',
      accessor: 'medical_facility_city',
      minWidth: 170,
      sortable: false,
      Cell: renderUnformatted,
    },
    {
      Header: 'Medical Facility State',
      accessor: 'medical_facility_state',
      minWidth: 170,
      sortable: false,
      Cell: renderUnformatted,
    },
    {
      Header: 'Medical Facility ZIP Code',
      accessor: 'medical_facility_zip',
      minWidth: 190,
      sortable: false,
      Cell: renderUnformatted,
    },
    {
      Header: 'Medical Codes',
      accessor: 'medical_codes',
      minWidth: 170,
      sortable: false,
      Cell: renderStatusCell,
    },
    {
      Header: 'Notes',
      accessor: 'notes',
      minWidth: 170,
      sortable: false,
      Cell: renderUnformatted,
    },
  ]
  , MEDICAL_UNDERWRITING_COLUMN_GROUP = [
    {
      Header: 'Est. Case Value',
      accessor: 'estimated_case_value',
      minWidth: 150,
      sortable: false,
      Cell: renderMoney,
    },
    {
      Header: 'Expected Settlement Date',
      accessor: 'expected_settlement_date',
      minWidth: 220,
      sortable: false,
      Cell: renderDate,
    },
  ]
  , GENERIC_LIEN_COLUMN_GROUP = [
    {
      Header: 'Lien Subtype',
      accessor: 'lien_subtype',
      minWidth: 180,
      sortable: false,
      Cell: renderUnformatted,
    },
    {
      Header: 'Acquisition Date',
      accessor: 'acquisition_date',
      minWidth: 150,
      Cell: renderDate,
    },
    {
      Header: HeaderDaysSinceAcquisition,
      accessor: 'days_since_acquisition',
      minWidth: 180,
      sortable: false,
      Cell: renderUnformatted,
    },
    {
      Header: 'Current Lien Balance',
      accessor: 'current_payoff',
      minWidth: 190,
      Cell: renderMoney,
    },
    LIEN_DATE_CLOSED_COLUMN,
  ]
  , GENERIC_LIEN_RETURN_COLUMN_GROUP = [
    {
      Header: 'Latest Maturity Date',
      accessor: 'date_matured',
      minWidth: 200,
      Cell: renderDate,
    },
    {
      Header: HeaderDaysToReturn,
      accessor: 'days_to_return',
      minWidth: 160,
      sortable: false,
      Cell: renderUnformatted,
    },
    AMT_OWED_TO_MIGHTY_COLUMN,
    {
      Header: 'Expected Amt',
      accessor: 'expected_amt',
      minWidth: 150,
      sortable: false,
      Cell: renderMoney,
    },
    {
      Header: 'Returned Amt',
      accessor: 'returned_amt',
      minWidth: 150,
      sortable: false,
      Cell: renderMoney,
    },
  ]
  , GENERIC_UNDERWRITING_COLUMN_GROUP = [
    {
      Header: 'Est. Case Value',
      accessor: 'estimated_case_value',
      minWidth: 150,
      sortable: false,
      Cell: renderMoney,
    },
    {
      Header: 'Expected Settlement Date',
      accessor: 'expected_settlement_date',
      minWidth: 220,
      sortable: false,
      Cell: renderDate,
    },
  ]
  , LIEN_REPORT_COLUMNS = [
    {
      Header: '',
      headerClassName: colStickyClass,
      columns: CASE_VANITY_ID_CELL,
    },
    {
      Header: 'Lien Information',
      columns: LIEN_FUNDING_COLUMN_GROUP,
    },
    {
      Header: 'Return Information',
      columns: LIEN_RETURN_COLUMN_GROUP,
    },
    {
      Header: 'Application Information',
      columns: LIEN_APPLICATION_COLUMN_GROUP,
    },
    {
      Header: 'Underwriting Information',
      columns: UNDERWRITING_COLUMN_GROUP,
    },
    {
      Header: 'Case Information',
      columns: CASE_INFO_COLUMN_GROUP,
    },
    {
      Header: 'Case Tracking Information',
      columns: LIEN_CASE_TRACKING_COLUMN_GROUP,
    },
    {
      Header: 'Plaintiff Information',
      columns: PLAINTIFF_INFO_COLUMN_GROUP,
    },
    {
      Header: 'Case Representatives',
      columns: CASE_REP_COLUMN_GROUP,
    },
    {
      Header: 'Lead Source',
      columns: LEAD_SOURCE_COLUMN_GROUP,
    },
    {
      Header: 'Buyout',
      columns: BUYOUT_COLUMN_GROUP,
    },
  ]
  , PLAINTIFF_FUNDING_CASE_REPORT_COLUMNS = [
    {
      Header: '',
      headerClassName: colStickyClass,
      columns: CASE_VANITY_ID_CELL,
    },
    {
      Header: 'Case Tracking Information',
      columns: CASE_TRACKING_COLUMN_GROUP,
    },
    {
      Header: 'Lien Information',
      columns: CASE_PLAINTIFF_FUNDING_COLUMN_GROUP,
    },
    {
      Header: 'Return Information',
      columns: CASE_RETURN_COLUMN_GROUP,
    },
    {
      Header: 'Underwriting Information',
      columns: UNDERWRITING_COLUMN_GROUP,
    },
    {
      Header: 'Case Information',
      columns: CASE_INFO_COLUMN_GROUP,
    },
    {
      Header: 'Plaintiff Information',
      columns: PLAINTIFF_INFO_COLUMN_GROUP,
    },
    {
      Header: 'Case Representatives',
      columns: CASE_REP_COLUMN_GROUP,
    },
    {
      Header: 'Lead Source',
      columns: LEAD_SOURCE_COLUMN_GROUP,
    },
  ]
  , MEDICAL_FUNDING_CASE_REPORT_COLUMNS = [
    {
      Header: '',
      headerClassName: colStickyClass,
      columns: CASE_VANITY_ID_CELL,
    },
    {
      Header: 'Case Tracking Information',
      columns: CASE_TRACKING_COLUMN_GROUP,
    },
    {
      Header: 'Lien Information',
      columns: CASE_MEDICAL_FUNDING_COLUMN_GROUP,
    },
    {
      Header: 'Return Information',
      columns: CASE_RETURN_COLUMN_GROUP,
    },
    {
      Header: 'Underwriting Information',
      columns: UNDERWRITING_COLUMN_GROUP,
    },
    {
      Header: 'Case Information',
      columns: CASE_INFO_COLUMN_GROUP,
    },
    {
      Header: 'Plaintiff Information',
      columns: PLAINTIFF_INFO_COLUMN_GROUP,
    },
    {
      Header: 'Case Representatives',
      columns: CASE_REP_COLUMN_GROUP,
    },
    {
      Header: 'Lead Source',
      columns: LEAD_SOURCE_COLUMN_GROUP,
    },
  ]
  , MEDICAL_LIEN_REPORT_COLUMNS = [
    {
      Header: '',
      headerClassName: colStickyClass,
      columns: CASE_VANITY_ID_CELL,
    },
    {
      Header: 'Lien Information',
      columns: MEDICAL_LIEN_FUNDING_COLUMN_GROUP,
    },
    {
      Header: 'Return Information',
      columns: LIEN_RETURN_COLUMN_GROUP,
    },
    {
      Header: 'Application Information',
      columns: MEDICAL_APPLICATION_COLUMN_GROUP,
    },
    {
      Header: 'Procedure Information',
      columns: MEDICAL_PROCEDURE_COLUMN_GROUP,
    },
    {
      Header: 'Underwriting Information',
      columns: MEDICAL_UNDERWRITING_COLUMN_GROUP,
    },
    {
      Header: 'Case Information',
      columns: CASE_INFO_COLUMN_GROUP,
    },
    {
      Header: 'Case Tracking Information',
      columns: LIEN_CASE_TRACKING_COLUMN_GROUP,
    },
    {
      Header: 'Plaintiff Information',
      columns: PLAINTIFF_INFO_COLUMN_GROUP,
    },
    {
      Header: 'Case Representatives',
      columns: CASE_REP_COLUMN_GROUP,
    },
    {
      Header: 'Lead Source',
      columns: LEAD_SOURCE_COLUMN_GROUP,
    },
  ]
  , GENERIC_LIEN_REPORT_COLUMNS = [
    {
      Header: '',
      headerClassName: colStickyClass,
      columns: CASE_VANITY_ID_CELL,
    },
    {
      Header: 'Lien Information',
      columns: GENERIC_LIEN_COLUMN_GROUP,
    },
    {
      Header: 'Return Information',
      columns: GENERIC_LIEN_RETURN_COLUMN_GROUP,
    },
    {
      Header: 'Underwriting Information',
      columns: GENERIC_UNDERWRITING_COLUMN_GROUP,
    },
    {
      Header: 'Case Information',
      columns: CASE_INFO_COLUMN_GROUP,
    },
    {
      Header: 'Case Tracking Information',
      columns: LIEN_CASE_TRACKING_COLUMN_GROUP,
    },
    {
      Header: 'Plaintiff Information',
      columns: PLAINTIFF_INFO_COLUMN_GROUP,
    },
    {
      Header: 'Case Representatives',
      columns: CASE_REP_COLUMN_GROUP,
    },
    {
      Header: 'Lead Source',
      columns: LEAD_SOURCE_COLUMN_GROUP,
    },
  ]
  , REPORT_COLUMNS = {
    'plaintiff-funding-case': PLAINTIFF_FUNDING_CASE_REPORT_COLUMNS,
    'medical-funding-case': MEDICAL_FUNDING_CASE_REPORT_COLUMNS,
    'lien': LIEN_REPORT_COLUMNS,
    'medical-lien': MEDICAL_LIEN_REPORT_COLUMNS,
    'generic-lien': GENERIC_LIEN_REPORT_COLUMNS,
  }
  ;

const REMOVED_DEFAULT_FIELDS = {
  'lien': [
    'agreement_date',
    'days_since_funding',
    'funded_amt',
    'capital_providers',
    'term_template',
    'amt_owed_to_mighty',
    'days_to_return',
    'expected_amt',
    'annualized_roi',
    'requested_amt',
    'attorney_email',
    'attorney_phone_number',
    'defendant',
    'plaintiff_birthdate',
    'plaintiff_email',
    'plaintiff_phone_number',
    'plaintiff_address',
    'plaintiff_city',
    'plaintiff_state',
    'plaintiff_zip',
    'lawfirm_address',
    'lawfirm_city',
    'lawfirm_state',
    'lawfirm_zip',
    'lawfirm_phone_number',
    'primary_rep',
    'secondary_rep',
    'tertiary_rep',
    'lead_source_name',
    'lead_source_category',
    'tracking_contact',
    'tracking_contact_email',
    'case_contacts',
    'buyout_funder_name',
    'buyout_amount',
    'buyout_expiration_date',
    'last_case_tracking_date',
    'last_case_tracking_note',
    'last_case_note_body',
    'last_case_note_date',
    'reduction_percentage',
    'plaintiff_name',
    'total_principal_application_fee',
    'total_principal_processing_fee',
    'total_principal_attorney_review_fee',
    'total_principal_broker_fee',
    'total_principal_other_fee',
    'total_flat_application_fee',
    'total_flat_processing_fee',
    'total_flat_attorney_review_fee',
    'total_flat_broker_fee',
    'total_flat_other_fee',
    'interest_start_date',
    'court_index_number',
  ],
  'plaintiff-funding-case': [
    'case_return_status',
    'last_case_tracking_date',
    'last_case_tracking_note',
    'case_tracking_follow_up_date',
    'funded_amt',
    'amt_owed_to_mighty',
    'expected_amt',
    'attorney_email',
    'attorney_phone_number',
    'defendant',
    'plaintiff_birthdate',
    'plaintiff_email',
    'plaintiff_phone_number',
    'plaintiff_address',
    'plaintiff_city',
    'plaintiff_state',
    'plaintiff_zip',
    'lawfirm_address',
    'lawfirm_city',
    'lawfirm_state',
    'lawfirm_zip',
    'lawfirm_phone_number',
    'plaintiff_insurer',
    'primary_rep',
    'secondary_rep',
    'tertiary_rep',
    'lead_source_name',
    'lead_source_category',
    'tracking_contact',
    'tracking_contact_email',
    'case_contacts',
    'last_case_note_body',
    'last_case_note_date',
    'reduction_percentage',
    'plaintiff_name',
    'court_index_number',
  ],
  'medical-funding-case': [
    'case_return_status',
    'last_case_tracking_date',
    'last_case_tracking_note',
    'case_tracking_follow_up_date',
    'amt_owed_to_mighty',
    'expected_amt',
    'attorney_email',
    'attorney_phone_number',
    'defendant',
    'plaintiff_birthdate',
    'plaintiff_email',
    'plaintiff_phone_number',
    'plaintiff_address',
    'plaintiff_city',
    'plaintiff_state',
    'plaintiff_zip',
    'lawfirm_address',
    'lawfirm_city',
    'lawfirm_state',
    'lawfirm_zip',
    'lawfirm_phone_number',
    'plaintiff_insurer',
    'primary_rep',
    'secondary_rep',
    'tertiary_rep',
    'lead_source_name',
    'lead_source_category',
    'tracking_contact',
    'tracking_contact_email',
    'case_contacts',
    'last_case_note_body',
    'last_case_note_date',
    'reduction_percentage',
    'plaintiff_name',
    'court_index_number',
  ],
  'medical-lien': [
    'days_since_purchase',
    'capital_providers',
    'amt_owed_to_mighty',
    'days_to_return',
    'expected_amt',
    'annualized_roi',
    'notes',
    'attorney_email',
    'attorney_phone_number',
    'defendant',
    'plaintiff_birthdate',
    'plaintiff_email',
    'plaintiff_phone_number',
    'plaintiff_address',
    'plaintiff_city',
    'plaintiff_state',
    'plaintiff_zip',
    'lawfirm_address',
    'lawfirm_city',
    'lawfirm_state',
    'lawfirm_zip',
    'lawfirm_phone_number',
    'medical_facility_contact',
    'medical_facility_address',
    'medical_facility_city',
    'medical_facility_state',
    'medical_facility_zip',
    'medical_codes',
    'primary_rep',
    'secondary_rep',
    'tertiary_rep',
    'lead_source_name',
    'lead_source_category',
    'tracking_contact',
    'tracking_contact_email',
    'case_contacts',
    'last_case_tracking_date',
    'last_case_tracking_note',
    'last_case_note_body',
    'last_case_note_date',
    'reduction_percentage',
    'plaintiff_name',
    'court_index_number',
  ],
  'generic-lien': [
    'days_since_acquisition',
    'amt_owed_to_mighty',
    'days_to_return',
    'expected_amt',
    'attorney_email',
    'attorney_phone_number',
    'lawfirm_phone_number',
    'defendant',
    'gross_profit',
    'plaintiff_email',
    'plaintiff_phone_number',
    'primary_rep',
    'secondary_rep',
    'tertiary_rep',
    'lead_source_name',
    'lead_source_category',
    'last_case_tracking_date',
    'last_case_tracking_note',
    'last_case_note_body',
    'last_case_note_date',
    'reduction_percentage',
    'plaintiff_name',
    'court_index_number',
  ],
};

const HEADER_COPY_CONFIG: { [tableType: string]: { objects: string, payoff: string } } = {
  'generic-lien': {
    objects: 'liens',
    payoff: 'payoff',
  },
  'lien': {
    objects: 'liens',
    payoff: 'payoff',
  },
  'medical-funding-case': {
    objects: 'cases',
    payoff: 'remaining billing amt',
  },
  'medical-lien': {
    objects: 'liens',
    payoff: 'remaining billing amt',
  },
  'plaintiff-funding-case': {
    objects: 'cases',
    payoff: 'payoff',
  },
};

const HEADER_COLUMNS = [
  {
    accessor: 'total',
    formatValue: formatCommaSeparatedNumber,
    renderLabel: (tableType: string) => `Total ${HEADER_COPY_CONFIG[tableType].objects}`,
  },
  {
    accessor: 'total_open',
    formatValue: formatCommaSeparatedNumber,
    renderLabel: (tableType: string) => `Open ${HEADER_COPY_CONFIG[tableType].objects}`,
  },
  {
    accessor: 'total_closed',
    formatValue: formatCommaSeparatedNumber,
    renderLabel: (tableType: string) => `Closed ${HEADER_COPY_CONFIG[tableType].objects}`,
  },
  {
    accessor: 'total_principal_amt',
    formatValue: formatMoney,
    renderLabel: 'Total Principal',
  },
  {
    accessor: 'total_returned_amt',
    formatValue: formatMoney,
    renderLabel: 'Total Returned',
  },
  {
    accessor: 'total_current_payoff',
    formatValue: formatMoney,
    renderLabel: (tableType: string) => `Total ${HEADER_COPY_CONFIG[tableType].payoff}`,
  },
];

export {
  GENERIC_LIEN_REPORT_COLUMNS,
  HEADER_COLUMNS,
  LIEN_REPORT_COLUMNS,
  MEDICAL_FUNDING_CASE_REPORT_COLUMNS,
  MEDICAL_LIEN_REPORT_COLUMNS,
  PLAINTIFF_FUNDING_CASE_REPORT_COLUMNS,
  REMOVED_DEFAULT_FIELDS,
  REPORT_COLUMNS,
  HeaderAnnualizedROI,
  HeaderDaysSinceFunding,
  HeaderDaysToReturn,
  HeaderROI,
  HeaderGrossProfit,
  HeaderPrincipalAmount,
  HeaderLienAmount,
  HeaderCurrentLienBalance,
  renderBlankCell,
  renderIsOpen,
  renderPhoneNumberArrayCell,
  renderSnakeCaseCell,
  renderStartCasedArrayCell,
  renderStatusCell,
  renderUnformattedArrayCell,
};
